export const defaultWidth = {
  units320: 320,
  units300: 300,
  units240: 240,
  units220: 220,
  units200: 200,
  units150: 150,
  units140: 140,
  units120: 120,
  units110: 110,
  units100: 100,
  units80: 80,
  units70: 70,
  units60: 60,
  units50: 50,
  units45: 45,
  units40: 40,
  units30: 30,
  units20: 20,
};

export const STATE_SUBSIDY_SAVE_TO_LOCAL = "subsidyManagementState";

export const MESSAGE_ERROR = "ERROR";

export const MESSAGE_DATA_NOT_FOUND = "DATA_NOT_FOUND";

export const OPTIONS_PLAN_SORT_PLAN = [
  { value: "riyousyaFurigana", display: "ふりがな" },
  { value: "planNengetu", display: "計画年月" },
  { value: "hokensyaNo", display: "保険者番号" },
  { value: "hihokenNo", display: "被保険者番号" },
  { value: "kaigodo", display: "要介護度" },
  { value: "kubun", display: "作成区分" },
  { value: "gendogaku", display: "限度額" },
  { value: "totalTani", display: "給付単位" },
  { value: "comment", display: "コメント" },
  { value: "mod", display: "更新日" },
];

export const OPTIONS_PLAN_SORT_SEIKYU = [
  { value: "riyousyaFurigana", display: "ふりがな" },
  { value: "seikyuNengetu", display: "請求年月" },
  { value: "hokensyaNo", display: "保険者番号" },
  { value: "hihokenNo", display: "被保険者番号" },
  { value: "kaigodo", display: "要介護度" },
  { value: "kubun", display: "作成区分" },
  { value: "gendogaku", display: "限度額" },
  { value: "totalTani", display: "給付単位" },
  { value: "comment", display: "コメント" },
  { value: "mod", display: "更新日" },
];

export const handleGetSortDataInit = (optionsSort) => {
  const resultObject = {};
  optionsSort.forEach((item, index) => {
    resultObject[`key${index + 1}`] = "";
    resultObject[`value${index + 1}`] = "asc";
  });

  return resultObject;
};
