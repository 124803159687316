export const subsidyManagementActionTypes = {
    GET_LIST_SUBSIDY: "GET_LIST_SUBSIDY",
    GET_LIST_SUBSIDY_SUCCESS: "GET_LIST_SUBSIDY_SUCCESS",
    GET_LIST_SUBSIDY_FAILURE: "GET_LIST_SUBSIDY_FAILURE",

    REDIRECT_TO_TICKET: "REDIRECT_TO_TICKET",
    REDIRECT_TO_TICKET_SUCCESS: "REDIRECT_TO_TICKET_SUCCESS",
    REDIRECT_TO_TICKET_FAILURE: "REDIRECT_TO_TICKET_FAILURE",

    DELETE_SUBSIDY: "DELETE_SUBSIDY",
    DELETE_SUBSIDY_SUCCESS: "DELETE_SUBSIDY_SUCCESS",
    DELETE_SUBSIDY_FAILURE: "DELETE_SUBSIDY_FAILURE",

    UPDATE_SUBSIDY: "UPDATE_SUBSIDY",
    UPDATE_SUBSIDY_SUCCESS: "UPDATE_SUBSIDY_SUCCESS",
    UPDATE_SUBSIDY_FAILURE: "UPDATE_SUBSIDY_FAILURE",

    UPDATE_SEIKYU: "UPDATE_SEIKYU",
    UPDATE_SEIKYU_SUCCESS: "UPDATE_SEIKYU_SUCCESS",
    UPDATE_SEIKYU_FAILURE: "UPDATE_SEIKYU_FAILURE",
};