import { Button, DatePicker, Modal } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";

function DateTimePicker(props) {
  const { MonthPicker } = DatePicker;
  const [dateSelected, setDateSelected] = useState();
  const today = new Date();
  const year = today.getFullYear();
  const months = today.getMonth() + 1;
  const month = months <= 9 ? "0" + months : months;
  const [defaultDate, setDefaultDate] = useState(year + "-" + month);

  function handleChangeDatePicker(date, dateString) {
    setDefaultDate(dateString);
    setDateSelected(dateString);
  }

  function onOk() {
    if (dateSelected != null && dateSelected != "undefined") {
      props.onSelectDatePicker(dateSelected);
    }
    else {
      props.onSelectDatePicker(year + "-" + month);
    }
  }

  function onCancel() {
    setDefaultDate(year + "-" + month);
    setDateSelected(year + "-" + month);
    props.onHideModal();
  }

  return (
    (<Modal
      open={props.visible}
      onCancel={props.onHideModal}
      width={375}
      footer={[
        <Button key="add" type={"primary"} onClick={onOk}>
          Ok
        </Button>,
        <Button key="canceladd" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      <MonthPicker
        style={{ width: "120px" }}
        value={dayjs(defaultDate)}
        defaultValue={dayjs(year + "-" + month)}
        onChange={handleChangeDatePicker}
        allowClear={false}
      />
    </Modal>)
  );
}

export { DateTimePicker };
