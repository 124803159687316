import { subsidyManagementActionTypes } from "../action_types";

const initialState = {
    subsidyList: [],
    loading: false,
    redirectToTicket: null
};

const subsidyManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case subsidyManagementActionTypes.GET_LIST_SUBSIDY:
            return { ...state, loading: true };
        case subsidyManagementActionTypes.GET_LIST_SUBSIDY_SUCCESS:
            return { ...state, subsidyList: action, loading: false };
        case subsidyManagementActionTypes.GET_LIST_SUBSIDY_FAILURE:
            return { ...state, subsidyList: action, loading: false };

        case subsidyManagementActionTypes.REDIRECT_TO_TICKET:
            return { ...state };
        case subsidyManagementActionTypes.REDIRECT_TO_TICKET_SUCCESS:
            return { ...state, redirectToTicket: action };
        case subsidyManagementActionTypes.REDIRECT_TO_TICKET_FAILURE:
            return { ...state, redirectToTicket: action };

        case subsidyManagementActionTypes.UPDATE_SUBSIDY:
            return { ...state };
        case subsidyManagementActionTypes.UPDATE_SUBSIDY_SUCCESS:
            return { ...state, updateSubsidy: action };
        case subsidyManagementActionTypes.UPDATE_SUBSIDY_FAILURE:
            return { ...state, updateSubsidy: action };

        default:
            return state;
    }
};

export default subsidyManagementReducer;